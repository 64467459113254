import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, Mousewheel, FreeMode } from "swiper/modules";
import * as tw from 'tailwind-config';

const tailwind = tw.config();

// Generic swiper
document.querySelectorAll("[data-slider]").forEach(function (el) {
  const id = el.getAttribute('data-slider');

  // Default options
  const args = {
    modules: [Navigation, Autoplay, Pagination, Mousewheel, FreeMode],
    spaceBetween: 12,
    slidesPerView: 1,
    breakpoints: {},
    navigation: {
      nextEl: document.querySelector(`[data-slider-nav-next="${id}"]`) || el.querySelector('& > div > .swiper-button-next'),
      prevEl: document.querySelector(`[data-slider-nav-prev="${id}"]`) || el.querySelector('& > div > .swiper-button-prev'),
    },
  }

  // Add loop
  if (el.hasAttribute('data-slider-loop')) {
    args.loop = true
  }

  // Add autoplay
  const getAutoplayDelay = () => {
    if (el.hasAttribute('data-slider-autoplay')) {
      const autoplay = el.getAttribute('data-slider-autoplay');
      return parseInt(autoplay) ? parseInt(autoplay) : 2000
    }
    return null
  }

  if (getAutoplayDelay()) {
    args.autoplay = { delay: getAutoplayDelay() }
  }

  // Add fraction pagination
  const fraction = document.querySelector(`[data-slider-fraction="${id}"]`);
  if (fraction) {
    args.pagination = {
      el: fraction,
      type: 'fraction', // Use fraction pagination
    }
  }

  // Add slides pre view
  if (el.hasAttribute('data-slider-slides-per-view')) {
    args.breakpoints = {
      ...args.breakpoints,
      0: {
        spaceBetween: 5,
        slidesPerView: el.getAttribute('data-slider-slides-per-view-sm') ? parseFloat(el.getAttribute('data-slider-slides-per-view-sm')) : 1,
      },

      [parseInt(tailwind.theme.screens.lg)]: {
        spaceBetween: 12,
        slidesPerView: parseFloat(el.getAttribute('data-slider-slides-per-view')),
      },
    }
  }

  // Add space between
  const spaceBetween = el.getAttribute('data-slider-space-between');
  if (spaceBetween) {
    args.spaceBetween = spaceBetween
  }

  // Touchbar support
  if (el.hasAttribute('data-slider-touchbar-enabled')) {
    args.mousewheel = {
      ...args.mousewheel,
      forceToAxis: true
    }
  }

  // Freemode support
  if (el.hasAttribute('data-slider-freemode')) {
    args.freeMode = true
  }

  new Swiper(el, args);
});
