// A11y
try {
  const dataContent = document.querySelector("[data-a11y-content]") || document.querySelector("main");
  const focusableElements = dataContent.querySelectorAll('a[href], button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
  const focusableElement = focusableElements.length && Object.values(focusableElements).find((el) => !el.hasAttribute("disabled") && el.offsetParent !== null) || dataContent.querySelector("h1, h2, h3");

  document.querySelectorAll("[data-a11y-link]").forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault();
      const target = el.getAttribute("href");

      if (target === "menu") {
        document.querySelector("[data-a11y-menu] summary").focus();
      } else if (target === "content") {
        if (focusableElements.length && ![...focusableElements].includes(focusableElement)) focusableElement.setAttribute("tabindex", "0");
        focusableElement.focus();
      }
    });
  });
} catch (error) {
  console.warn(error)
}
