// TAILWIND
import * as tw from 'tailwind-config';
export const tailwind = tw.config();

if (!window.tailwind) {
  window.tailwind = tailwind;
}

// Update window width on resize
window.width = document.documentElement.clientWidth;
window.addEventListener("resize", () => {
  window.width = document.documentElement.clientWidth;
});

// Strech element to the right side of the screen
function toRight() {
  document.querySelectorAll("[data-to-right]").forEach(function (el) {
    el.removeAttribute("style");
    if (window.width > parseInt(tailwind.theme.screens.md)) {
      el.style.width =
        window.innerWidth - el.getBoundingClientRect().left + "px";
    }
  });
}

document.addEventListener("DOMContentLoaded", function () {
  toRight();
});
window.addEventListener("resize", function () {
  toRight();
});

// Reset the scroll position to the top of the page
window.addEventListener('beforeunload', function () {
  window.scrollTo(0, 0);
});


// Add color to header once user scrolls down on page
window.addEventListener('scroll', function () {
  var header = document.getElementById('header');
  var logoMobile = document.getElementById('logoMobile');
  var logoDesktop = document.getElementById('logoDesktop');
  var scrollPosition = window.scrollY;

  if (scrollPosition > 0) {
    header.classList.add('header-colored');
    header.classList.remove('header-transparent');
    logoMobile.src = '/media/brand/nvteh-dark-logo.png'
    logoDesktop.src = '/media/brand/nvteh-dark-logo.png'
  } else {
    header.classList.remove('header-colored');
    header.classList.add('header-transparent');

    if (window.location.pathname === '/') {
      logoMobile.src = '/media/brand/nvteh-light-logo.png'
      logoDesktop.src = '/media/brand/nvteh-light-logo.png'
    } else {
      logoMobile.src = '/media/brand/nvteh-dark-logo.png'
      logoDesktop.src = '/media/brand/nvteh-dark-logo.png'
    }
  }
});

import "../components/header/script.js";
import "../components/section/script.js";
import "../components/a11y/script.js";
import "../scripts/swiper.js";
